* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.logo {
    width: 71px;
}

header {
    background-color: #000;
}

@import url("https://fonts.googleapis.com/css?family=Titillium+Web");

* {
    font-family: "Titillium Web", sans-serif;
}

/* body {
  height: 2000px;
} */
.navbar .navbar-brand {
    font-size: 30px;
}

.fa-bars {
    color: #007bff;
    font-size: 30px;
}

.nav-link {
    padding: 0px !important;
    margin: 0px !important;
    color: #fff !important;
    font-size: 22px;
    border-bottom: 3px solid #000;
}

.mukta-regular,
.footer_list_link {
    font-family: "Mukta", sans-serif;
}

.poppins {
    font-family: "Poppins", sans-serif;
}

.w {
    color: #fff;
}

.bl {
    color: #00C4F4;
}

.o {
    color: #eead0e;
}

.g {
    color: #0FB267;
}

.r {
    color: #ED0A41;
}

.navbar_list {
    gap: 50px;
}

a {
    text-decoration: none !important;
}

.contact_bg,
.contact_bg1 {
    background-color: #000;
    padding: 8px 30px;
    border-radius: 33px;
    font-size: 21px;
    color: #fff;
}

.contact,
.contact1 {
    background: linear-gradient(90deg, #0088FF 0%, rgba(4, 12, 21, 1) 49%, #ED0A41 100%);
    border-radius: 33px;
    padding: 15px 3px 11px;
}

.contact_bg:hover,
.contact_bg1:hover {
    border: 3px solid #00c4f4;
    margin: -3px;
}

.banner {
    background: linear-gradient(23deg, #030b15 5.68%, rgba(3, 11, 21, .42) 81.9%), url(../img/banner_bg.jpg);
    padding: 80px 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
}

.main-heading {
    font-size: 55px;
    font-weight: 600;
    line-height: 82px;
}

.main-heading span,
.token {
    background: -webkit-linear-gradient(#0088FF 25%, #ED0A41);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-progress-wrap {
    max-width: 700px;
    margin: auto;
}

ul {
    list-style-type: none;
    padding: 0px !important;
}

.progress_list {
    display: flex;
    justify-content: space-between;
}

.progress_list_item {
    font-size: 18px;
    font-weight: 700;
    position: relative;
}

.progress_list_item:before {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 14px;
    top: 29px;
}

.progress_list_item1:before {
    background: #00c4f4;
}

.progress_list_item2:before {
    background: #eead0e;
}

.progress_list_item3:before {
    background: #0FB267;
}

.progres {
    display: flex;
    height: 7px;
    overflow: hidden;
    font-size: .75rem;
    background-color: #fff;
    border-radius: 0.25rem;
    margin-top: 40px;
    position: relative;
}

.progress-bar {
    width: 70%;
}

.nav-link:hover,
.nav-item.active.nav-link {
    border-bottom: 3px solid #00c4f4;
}

.progress-dot {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 100%;
    border: 5px solid #00c4f4;
    margin-left: 70%;
    margin-top: -18px;
}

.progress-bar {
    background-color: #00c4f4 !important;
}

.banner-progress-wrap {
    margin-top: 50px;
}

.progress_title {
    font-size: 15px;
    font-weight: 600;
}

.progress_text {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.sec02,
.sec03,
.sec04,
.sec05 {
    background-color: #030b15;
}

.sec2-title {
    text-align: center;
    font-size: 36px;
}

.coming-time {
    max-width: 100%;
    margin: 30px auto 0px;
}

.coming_box {
    background-color: #0B1D33;
    padding: 15px 10px;
    text-align: center;
    border-radius: 10px;
    height: 110px;
}

.coming_box_title {
    font-size: 40px;
    font-weight: 600;
}

.coming_box_text {
    font-size: 17px;
    margin-bottom: 0px !important;
    font-weight: 400;
}

.margin-top {
    margin-top: 80px;
}

.about_title {
    font-size: 36px;
}

.about_sub i {
    font-size: 6px;
}

.about_sub {
    font-size: 16px;
}


.about_title {
    font-size: 41px;
    font-weight: 600;
    margin-top: 30px;
}

.align-self {
    align-self: center;
}

.about_text {
    font-size: 22px;
    margin-bottom: 50px;
    color: #b6b6b6;
    margin-top: 25px;
}

.center {
    text-align: center;
}

.icon-box01 {
    position: relative;
}

.sec03,
.sec04 {
    padding-top: 80px;
}

.sec04 {
    padding: 80px 0px;
}

.partner {
    padding: 20px;
    opacity: 0.4;
}

.partner:hover {
    opacity: 9;
}

.choose-item {
    background: #030b15;
    border: 1px solid #121a23;
    border-radius: 15px;
    padding: 20px;
    transition: .3s ease-in-out;
    text-align: center;
}

.choose-icon-box {
    background-color: #1d1d1d;
    padding: 15px;
    display: inline-block;
    border-radius: 100px;
    border: 5px solid #2b2a2a;
    text-align: center;
}

.choose-icon-box img {
    width: 70px;
}

.choose_title {
    font-size: 26px;
    margin: 20px 0px;
}

.choose_text {
    font-size: 18px;
    margin: 0px;
}

.choose-item:hover .choose-icon-box {
    border-color: #00c4f4 !important;
}

.choose-item:hover .choose_title {
    color: #00c4f4;
}

.choose_row,
.crypto1 {
    margin-top: 45px;
}

.box_icon i {
    font-size: 40px;
    background-color: #0B1D33;
    border-radius: 100px;
    border: 2px solid;
    padding: 25px;
}


.box_icon {
    display: inline-block;
    padding: 10px;
    border-radius: 100px;
}

.p-11 {
    border: 3px solid #ef8202;
}

.p-12 {
    border: 3px solid #f3ba09;
}

.p-13 {
    border: 3px solid #23c9c6;
}


.box_icon i .o {
    border-color: #eead0e;
}

.icon_title {
    color: #fff !important;
    font-size: 22px;
    font-weight: 600;
    line-height: 43px;
}

.contact_chart {
    background-color: #0B1D33;
    padding: 40px 30px;
}

.contact_box {
    margin-top: 35px;
    border-radius: 20px;
}

.contact_form {
    background-color: #071425;
    padding: 45px 30px;
}

.char_img {
    width: 300px;
    margin-bottom: 35px;
}

@media (max-width: 762px) {
    .char_img {
        width: 100%;
    }
}

.b {
    color: blue;
}

.gp {
    color: #5DD400;
}

.gr {
    color: #005F73;
}

.list {
    margin-bottom: 0px;
}

.list_item.w.poppins {
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
}

.input_form {
    display: block;
    width: 100%;
    padding: 10px 30px;
    margin-bottom: 24px;
    background-color: #000;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

input:focus-visible,
textarea:focus-visible {
    outline: 0px;
}

.contact1 {
    padding: 13px 3px 12px;
}

.contact_bg1 {
    padding: 7px 30px;
    border: none;
}

.sec05 {
    padding-bottom: 80px;
    position: relative;

}

footer {
    background-color: #000;
    padding: 40px 0px;
}

.footer_text {
    font-size: 16px;
    margin-top: 19px;
}

.socl_media_icon i {
    color: #fff;
    /* align-items: center; */
    /* margin: auto; */
    position: relative;
    bottom: 4px;
}

.socl_media_icon {
    padding: 8px;
    background-color: #ff000000;
    border: 2px solid #ffffff7d;
    border-radius: 100%;
    margin-right: 4px;
    padding-bottom: 1px;
}

.socl_media_icon i {
    color: #fff;
}

.socl_media_icon:hover {
    border-color: #00C4F4;
}

.socl_media_icon:hover i {
    color: #00C4F4;
}

.footer_title {
    font-size: 20px;
    margin: 20px 0px;
}

.align-b {
    align-self: end;
}

.footer_list_link {
    font-size: 17px;
    line-height: 31px;
    color: #fff;
}

.footer_list_link:hover {
    color: #00C4F4;
    border-bottom: 2px solid #fff;
}

.footer_col1 {
    padding-right: 40px;
}

.top_btn {
    width: 100%;
    height: 1px;
    background-color: #282222;
    margin: 50px 0px;
}

.top_icon_main_box {
    position: relative;
}

.top_icon_box {
    display: inline-block;
    padding: 20px 25px;
    border-radius: 100%;
    /* border: 1px solid #ffffff4a; */
    position: absolute;
    top: -39px;
    left: 47%;
    background: #000;
}

.top_icon i {
    font-size: 32px;
    position: relative;
    z-index: 9999;
}

.top_icon_box::after {
    content: "";
    left: 7px;
    top: 6px;
    width: 60px;
    height: 60px;
    background: 0 0;
    border: 2px dashed #00C4F4;
    border-radius: 50%;
    position: absolute;
    transition: opacity .3s linear;
    animation: teamRotate 10s linear infinite;
}

@keyframes teamRotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.banner {
    position: relative;
}

.banner_shape01 {
    position: absolute;
    top: 30%;
    left: -32px;
    animation: leftToRight 5s infinite linear;
}

@keyframes leftToRight {
    0% {
        transform: rotateX(0deg) translateX(0px);
    }

    50% {
        transform: rotateX(0deg) translateX(50px);
    }

    100% {
        transform: rotateX(0deg) translateX(0px);
    }
}

.banner_shape02 {
    left: auto;
    right: 1%;
    top: auto;
    position: absolute;
    bottom: 14%;
    animation: alltuchtopdown 3s infinite linear;
    width: 150px;
}

@keyframes alltuchtopdown {

    0% {
        transform: rotateX(0deg) translateY(0px);
    }

    50% {
        transform: rotateX(0deg) translateY(-30px);
    }

    100% {
        transform: rotateX(0deg) translateY(0px);
    }
}

.about_img {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 762px) {
    .about_img {
        width: 100%;
    }
}

.about_title {
    font-size: 26px;
}

.choose_title {
    font-size: 22px;
}

.choose_text {
    font-size: 16px;
    line-height: 20px;
}

.icon_title {
    font-size: 18px;
    line-height: 30px;
}

.list_item.w.poppins {
    font-size: 12px;
}

.footer_title {
    font-size: 17px;
}

.nav-link {
    font-size: 18px;
}



@media (max-width: 992px) {
    .coming_box_text{
        font-size: 14px;
    }
    .main-heading {
        font-size: 30px;
        line-height: 45px;
    }

    .about_title {
        font-size: 32px;
    }

    .choose_title {
        font-size: 22px;
    }

    .choose_text {
        font-size: 16px;
        line-height: 20px;
    }

    .icon_title {
        font-size: 18px;
        line-height: 30px;
    }

    .list_item.w.poppins {
        font-size: 12px;
    }

    .footer_title {
        font-size: 17px;
    }

    .nav-link {
        font-size: 18px;
    }

}

@media (max-width: 767px) {
    .banner {
        padding: 40px 15px;
    }

    .main-heading {
        font-size: 30px;
        line-height: 46px;
    }

    .progress_title {
        font-size: 11px;
        font-weight: 600;
    }

    .progress_list_item {
        font-size: 14px;
    }

    .banner_shape01 {
        width: 100px;
    }

    .banner_shape02 {
        width: 80px;
    }

    .sec2-title {
        font-size: 24px;
    }

    .about_title {
        font-size: 22px;
    }

    .logo {
        width: 41px;
    }

    .sec04 {
        padding: 40px 0px;
    }

    .choose-item {
        margin-top: 20px;
    }

    .cauntar1 {
        margin-top: 20px !important;
    }

    /* .cauntar {
        width: 50% !important;
    } */

    .col-lg-6.align-self {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .about_title {
        margin-top: 20px;
    }

    .sec05 {
        padding-bottom: 40px;
    }

    .icon_title {
        font-size: 16px;
        line-height: 30px;
    }

    .top_icon_box {
        left: 41%;
    }

    .choose_title {
        font-size: 18px;
    }

    .about_sub {
        font-size: 13px;
    }

    nav.navbar.sticky-top.navbar-expand-lg {
        padding: 10px 0px;
    }

    .icon-box02 {
        margin: 40px 0px;
    }

    .choose_row {
        margin-top: 0px;
    }

    .coming_box{
        margin-top: 30px;
    }
    

}

.navbar-toggler i {
    font-size: 23px;
}

@media (min-width: 768px) {
    .icon-box01:before {
        content: "";
        position: absolute;
        right: -61px;
        top: 44px;
        background-image: url(../img/line.png);
        width: 122px;
        height: 9px;
    }
    .about_title {
        font-size: 17px !important;
    }
    .about_text{
        font-size: 17px !important;
    }

}





.main-heading,
.banner-progress-wrap {
    position: relative;
    z-index: 9;
}


.f-footer-img {
    background-image: url(../img/footer-img.png);
    width: 100%;
    background-size: 100% 100%;
    height: 536px;
    position: relative;

}

.f-img-content {
    position: absolute;
    bottom: 30px;
    margin-left: 0px;
    width: 100%;
    left: 0;
}

.f-img-content h3 {
    color: #fff;
    text-align: center;
}
.f-img-content p {
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.footer-logo-img {
    width: 40%;
}

@media (max-width: 479px) {
    
    .col-12-1{
        width: 100% !important;
    }
    .coming_box_text{
        font-size: 16px;
    }
    .main-heading {
        font-size: 20px;
        line-height: 30px;
    }
    .h2-text-size {
        color: #fff;
        font-size: 16px !important;
    }
    .about_title {
        font-size: 17px !important;
    }
    .home-page-about {
        width: 100% !important;
        text-align: center !important;
    }
    .about_text {
        font-size: 14px !important;
    }
    
}
