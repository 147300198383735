* { margin: 0px;
    padding: 0px;
    box-sizing: border-box;}

.sec01 { background-color: #000;
    height: 100%;
        padding: 80px 20px;}

.contact_box {
    margin-top: 35px;
    border-radius: 20px;
}

.contact_chart {
    background-color: #0B1D33;
    padding: 40px 30px;
}

.contact_form {
    background-color: #071425;
    padding: 45px 30px;
}

.input_form {
    display: block;
    width: 100%;
    padding: 10px 30px;
    margin-bottom: 24px;
    background-color: #000;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.contact1 {
    background: linear-gradient(90deg, #0088FF 0%, rgba(4, 12, 21, 1) 49%, #ED0A41 100%);
    border-radius: 33px;
    padding: 16px 3px 13px;
    display: inline;
}

.contact_bg1 {
    background-color: #000;
    padding: 9px 30px;
    border: none;
    border-radius: 33px;
    font-size: 21px;
    color: #fff;
}

.about_img {
    width: 75%;
}

.w { color: #fff;}

.input_label {
    font-size: 19px;
    color: #a19696 !important;
    font-weight: 600;
}

.margin_top { margin-top: 30px;}

.logo-reg {
    width: 80px;
    margin-bottom: 52px;
}

.poppins {
    font-family: "Poppins", sans-serif;
}

.input_label1.w.poppins {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 18px;
}

/* .contact_bg1 {
    width: 100%;
} */

.contact1:hover {
    border: 3px solid #00c4f4;
    padding: 13px 0px 10px;
}


/* swal */

/* Define your custom styles */
.custom-swal-popup {
    background-color: #071425;
    border-radius: 30px;
    
  }
  .custom-swal-icon{
    color: red;
  }
  .custom-swal-title {
    color: red;
    font-size: 24px;
  }
  .custom-swal-text {
    color: #ffffff;
    font-size: 16px;
  }
  .custom-swal-footer {
    color: #afb8c8;
    font-size: 14px;
  }
  .custom-swal-confirm-button {
    background-color: #f56565;
    color: #ffffff;
  }
  .custom-swal-cancel-button {
    background-color: #cbd5e0;
    color: #4a5568;
  }
  
@media (max-width: 992px) {

    .sec01 { padding: 40px 20px;
            height: 100% !important;}

            img.about_img {
                margin-bottom: 40px;
            }

            .contact_form {
                background-color: #071425;
                padding: 45px 15px;
            }
            
}
@media (max-width: 767px) {
    .welcome-row{
        width: 560px;
    }
}
@media (max-width: 576px) {
    .welcome-row{
        width: 470px;
    }
}

@media (max-width: 479px) {
    .hh1{
        font-size: 20px;
    }
    .welcome-row{
        width: 400px;
    }
    .col-12-1{
        width: 100% !important;
    }
    .coming_box_text{
        font-size: 16px;
    }
    .main-heading {
        font-size: 20px;
        line-height: 30px;
    }
    .h2-text-size {
        color: #fff;
        font-size: 16px !important;
    }
    .about_title {
        font-size: 17px !important;
    }
    .home-page-about {
        width: 100% !important;
        text-align: center !important;
    }
    .about_text {
        font-size: 14px !important;
    }
}

.register-row{
    align-items: center;
}
@media (max-width: 400px) {
    .welcome-row{
        width: 320px;
    }
    .hh1{
        font-size: 17px;
    }
}