* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logo {
    width: 71px;
}

.contact {
    background: linear-gradient(90deg, #0088FF 0%, rgba(4, 12, 21, 1) 49%, #ED0A41 100%);
    border-radius: 33px;
    padding: 15px 3px 11px;
}

.contact_bg,
.contact_bg1 {
    background-color: #000;
    padding: 6px 30px;
    border-radius: 33px;
    font-size: 21px;
    color: #fff;
}

.contact_bg:hover,
.contact_bg1:hover {
    border: 3px solid #00c4f4;
    margin: -3px;
}

.contact_bg:hover,
.contact_bg1:hover {
    border: 3px solid #00c4f4;
    margin: -3px;
}

a {
    text-decoration: none !important;
}

.align-self {
    align-self: center;
}


.mukta-regular,
.footer_list_link {
    font-family: "Mukta", sans-serif;
}

.box_01 {
    /* background-color: #292a2cb0; */
    border-radius: 10px;
    position: relative;
    padding: 20px;
}

.ani02::before {
    width: 96%;
    height: 10px;
    content: "";
    background-color: #0000ff73;
    position: absolute;
    border-radius: 0px 0px 33px 33px;
    bottom: -19px;
    left: 24px;
    z-index: 1;
}

.ani02::after {
    width: 98%;
    height: 10px;
    content: "";
    background-color: #0000ffcc;
    position: absolute;
    border-radius: 0px 0px 33px 33px;
    bottom: -10px;
    left: 13px;
    z-index: 1;
}

.sec02,
.sec03,
.sec04,
footer {
    background-color: #030b15;
}

.sec02 {
    padding: 80px 0px 40px;
}

.sec03,
.sec04,
footer {
    padding: 40px 0px;
}
footer{
    padding-bottom: 60px;
}
.w {
    color: #fff;
}

.banner_text {
    font-size: 20px;
    margin-top: 25px;
}

.box_01 {
    text-align: center;
}

.banner_title {
    font-size: 33px;
    font-weight: 700;
}

.poppins {
    font-family: "Poppins", sans-serif;
}

.ani02 {
    position: relative;
    z-index: 0;
}

.ani {
    overflow: hidden;
    position: relative;
    height: 131px;
    border-radius: 10px;
}

.contexts {
    width: 100%;
    position: absolute;
    /* top: 23px; */
    background: url(../assets/img/conn-banner.png);
    height: 241px;
    z-index: 99999;
    background-size: 100% 100%;
}

.conn-banner-content {
    text-align: end !important;
}

.conn-btn-mt {
    margin-top: 50px !important;
}

.wid img {
    width: 100%;
}

.wid-content p {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
    /* letter-spacing: 1.8px; */
    font-family: "Titillium Web", sans-serif;
}

.conn-h5 {
    font-size: 30px;
    margin-bottom: 30px;
}

.conn-row {
    align-items: center;
}

.personal_link_box {
    justify-content: space-between;
}

.personal_link_box {
    padding: 10px 20px;
    border-radius: 10px;
}

.area {
    background: #292a2cb0;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.ani {
    overflow: hidden;
    /* background-color: #406AFF; */
    position: relative;
    height: 240px;
    border-radius: 10px;
}

.banner_img {
    width: 300px;
}

.ani02 {
    position: relative;
}

.partners-text {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 800;
}

.form_box {
    background-color: #202948;
    padding: 30px;
    border-radius: 10px;
}

.know {
    background-color: #191818;
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.partners-title {
    font-size: 17px;
}

.partners4 {
    width: 237px;
    position: absolute;
    top: 1px;
    left: 50%;
}

.check_demo {
    background-color: #3A3B3C;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    margin-top: 17px !important;
    display: inline-block;
    cursor: pointer;
}

input.form_input {
    padding: 10px;
    background: #000;
    border-radius: 10px;
    border: none;
    width: 80%;
    color: #fff;
    font-size: 18px;
    margin-top: 16px;
    margin-right: 22px;
}

.submit {
    padding: 9px 25px;
    border-radius: 10px;
    border: none;
    font-size: 19px;
    font-weight: 700;
    background: blue;
    color: #fff;
}

.roadmap_box {
    background-color: #242526;
    border-radius: 10px;
    padding: 20px;
}

.roadmap_title {
    margin-bottom: 30px;
}

.roadmap_box_img {
    height: 80px;
    margin-bottom: 20px;
    width: 100px;
    object-fit: contain;
}

.roadmap_box_text {
    font-size: 18px;
}

.roadmap_box_icon {
    margin-top: 27px;
}

.roadmap_box_icon i {
    font-size: 40px;
    color: #07bc0c;
    position: relative;
    z-index: 9;
}

.roadmap_box {
    margin: 0px 20px;
}

.roadmap_box_icon_text {
    font-size: 22px;
    font-weight: 600;
    margin-top: 11px;
    color: #7a7a7a;
}

.hr_line {
    width: 100%;
    height: 2px;
    background-color: #727272;
    position: relative;
    top: 49px;
    z-index: 0;
}


.col-lg-3.text-center.mt-3 {
    padding: 0px;
}

.table-recent,
.partners-table-box,
.link_sen2,
.chart_01 {
    background-color: #191818;
    padding: 20px;
    border-radius: 10px;
}

.d-flex.part20 {
    justify-content: space-between;
}

.table-row {
    border-bottom: 1px solid #4a4a4a;
    padding: 15px 0px;
    animation-delay: 3s;
}

.user {
    background-color: #4f5255;
    color: #fff;
    padding: 10px;
    border-radius: 100%;
    font-size: 20px;
}

.joined {
    color: #929292;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
}

.user-id {
    color: #008fff;
    background-color: #272C3C;
    padding: 5px 10px;
    border-radius: 33px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
}

.align-self {
    align-self: center;
}

.minutes {
    color: #9a9090;
    font-size: 16px;
    margin-bottom: 0px;
}

.arrow-up-right {
    font-size: 15px;
    margin-right: 15px;
    color: #c81e60 !important;
}

.see-more {
    background-color: #3d3d3d;
    width: 100%;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.members-title {
    font-size: 14px;
    color: #727272;
    margin-bottom: 20px;
}

.x3_title {
    font-size: 14px;
    font-weight: 500;
}

.g {
    color: #07bc0c;
}

.border-w {
    border: 1px solid #ffffff1f;
    margin-top: 15px;
    margin-bottom: 15px;
}

.x3-text {
    font-size: 13px;
}

.margin-top {
    margin-top: 33px;
}

.socl_media_icon {
    padding: 8px;
    background-color: #ff000000;
    border: 2px solid #ffffff7d;
    border-radius: 100%;
    margin-right: 4px;
}

.socl_media_icon:hover {
    border-color: #00C4F4;
}

.socl_media_icon:hover i {
    color: #00C4F4;
}

.container {
    max-width: 1280px !important;
}




@media (max-width: 767px) {
    .contact {
        padding: 7px 3px 8px;
    }

    .contact_bg,
    .contact_bg1 {
        padding: 6px 17px;
        font-size: 15px;
    }

    .logo {
        width: 50px;
    }

    .sec02 {
        padding: 30px 0px 20px;
    }

    .banner_title {
        font-size: 27px;
        font-weight: 700;
    }

    .banner_text {
        font-size: 16px;
        margin-top: 9px;
    }

    .ani02::before {
        width: 89%;
        left: 15px;
    }

    .ani02::after {
        width: 92%;
    }

    .partners-text {
        font-size: 24px;
    }

    .form_box,
    .know {
        padding: 20px;
    }

    .know {
        margin-top: 20px;
    }

    .roadmap_title {
        margin-bottom: 14px;
    }

    .hr_line {
        top: 40px;
    }

    .roadmap_box_icon i {
        font-size: 26px;
    }

    .roadmap_box_icon_text {
        font-size: 16px;
    }

    input.form_input {
        width: 100%;
        font-size: 16px;
        margin-top: 10px;
        margin-right: 0px;
    }

    .sec03,
    .sec04,
    footer {
        padding: 20px 0px;
    }

    .submit,
    .check_demo {
        padding: 6px 25px;
        font-size: 17px;
        margin-top: 15px;
    }

    .icon-box1 div i {
        font-size: 10px;
    }

    .joined {
        font-size: 12px;
        margin-left: 1px;
    }

    .minutes {
        font-size: 11px;
    }

    .see-more {
        font-size: 16px;
    }

    .user-id {
        font-size: 11px;
        margin-left: 8px;
    }

    .table-recent.members_received {
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .sec02 {
        padding: 40px 0px 40px;
    }

}

.welcome-user-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.welcome-row {
    background-color: #071425;
    padding: 45px 30px;
    width: 700px;
}

.welcome-logo {
    text-align: center;
}

.welcome-logo img {
    width: 70px;
    text-align: center;
}

.welcome-content h2 {
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 20px;
}


.token-transfar-content {}

.token-transfar-content h2 {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
}

.token-transfar-content p {
    color: #fff;
    margin-bottom: 30px;
}

.token-transfar-btn {}


@media (max-width: 768px) {
    .box_01.conn-banner-content {
        text-align: center !important;
    }
    .home-page-about{
        width: 400px !important;
        text-align: center !important;
    }
    .col-md-center{
        text-align: center !important;
    }
    .footer_col1 img{
        width: 100px;
    }
    
}
@media (max-width: 576px) {
    .wid-content p{
        font-size: 15px;
        font-weight: 500;
    }
    .cont-overflow-scroll{
        overflow-x: scroll;
    }
    .table-recent{
        width: 576px;
    }
    .footer_col1{
        text-align: center;
        padding: 0;
    }
    .footer_col1 img{
        width: 100px;
    }
    .t-center{
        text-align: center;
    }
    .pp-1{
        padding: 50px 0 0 0;
    }
}
@media (max-width: 360px) {
    .cont-1{
            height: auto !important;
    }
    
}



.b-btn-connect{
    padding: 8px 3px 8px !important; 
}
.btn-span{

}