.hhh{
    color: red;
}
:root {
    --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav {
    display: block;
}

body {
    margin: 0;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #515151;
    text-align: left;
    background-color: #e9edf4;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #3f84fc;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0458eb;
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Nunito", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 2.5rem;
    font-weight: normal;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
}

.card-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    text-align: center;
}

.dashboard {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
}

.dashboard-app {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    /* margin-top: 84px; */
}

.dashboard-content {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    padding: 25px;
}

.dashboard-nav {
    min-width: 238px;
    position: fixed;
    /* left: 0; */
    top: 0;
    bottom: 0;
    overflow: auto;
    background-color: #373193;
}

.dashboard-compact .dashboard-nav {
    display: none;
}

.dashboard-nav header {
    min-height: 84px;
    padding: 8px 15px 8px 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; */
}

.dashboard-nav header .menu-toggle {
    display: none;
    /* margin-right: auto; */
}

.dashboard-nav a {
    color: #515151;
}

.dashboard-nav a:hover {
    text-decoration: none;
}

.dashboard-nav {
    background-color: #000;
}

.dashboard-nav a {
    color: #fff;
}

.brand-logo {
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    font-size: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #515151;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.brand-logo:focus, .brand-logo:active, .brand-logo:hover {
    color: #dbdbdb;
    text-decoration: none;
}

.brand-logo i {
    color: #d2d1d1;
    font-size: 27px;
    margin-right: 10px;
}

.dashboard-nav-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dashboard-nav-item {
    min-height: 40px;
    padding: 8px 20px 8px 70px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    transition: ease-out 0.5s;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashboard-nav-item i {
    width: 36px;
    font-size: 19px;
    margin-left: -40px;
}

.dashboard-nav-item:hover {
    background: rgba(255, 255, 255, 0.04);
}

.active {
    background: rgba(0, 0, 0, 0.1);
}

.dashboard-nav-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dashboard-nav-dropdown.show {
    background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle {
    font-weight: bold;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle:after {
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.dashboard-nav-dropdown-toggle:after {
    content: "";
    margin-left: auto;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(81, 81, 81, 0.8);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
    border-top-color: rgba(255, 255, 255, 0.72);
}

.dashboard-nav-dropdown-menu {
    /* display: none; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dashboard-nav-dropdown-item {
    min-height: 40px;
    padding: 8px 20px 8px 70px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    transition: ease-out 0.5s;
}

.dashboard-nav-dropdown-item:hover {
    background: rgba(255, 255, 255, 0.04);
}

.menu-toggle {
    position: relative;
    width: 42px;
    height: 42px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #443ea2;
}

.menu-toggle:hover, .menu-toggle:active, .menu-toggle:focus {
    text-decoration: none;
    color: #875de5;
}

.menu-toggle i {
    font-size: 20px;
}

.dashboard-toolbar {
    min-height: 58px;
    background-color: #000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 0px;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
}

.nav-item-divider {
    height: 1px;
    margin: 1rem 0;
    overflow: hidden;
    background-color: rgba(236, 238, 239, 0.3);
}

@media (min-width: 992px) {
    .dashboard-app {
        margin-left: 238px;
    }

    .dashboard-compact .dashboard-app {
        margin-left: 0;
    }
}


@media (max-width: 768px) {
    .dashboard-content {
        padding: 15px 0px;
    }
}

@media (max-width: 992px) {
    .dashboard-nav {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1070;
    }

    .dashboard-nav.mobile-show {
        display: block;
    }
}

@media (max-width: 992px) {
    .dashboard-nav header .menu-toggle {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 992px) {
    /* .dashboard-toolbar {
        left: 238px;
    } */

    .dashboard-compact .dashboard-toolbar {
        left: 0;
    }
}

a { text-decoration: none !important;}

.dashboard-nav-item.dashboard {
    background-color: #242526;
    margin: 0px 20px;
    text-align: center;
    padding: 10px 48px;
    border-radius: 10px;
    font-size: 17px;
    color: #fff !important;
}

.dashboard-nav-dropdown.show  {
    background-color: #242526;
    border-radius: 10px;
}

body {
    background-color: #000 !important; 
}


@media (min-width: 992px) { 
    .dashboard-toolbar { display: none;}
}

/* .dashboard-nav-dropdown{
    margin: 0px 20px;
} */

.team-btn:hover, .dashboard-nav-item:hover {
    background-color: #242526;
    border-radius: 10px;
}

.dashboard-nav {
    border-right: 1px solid #282828;
}

.dashboard-nav-item  { margin-right: 20px;
                    margin-left: 20px;}


.icon-box-text {
    align-self: center;
}

.icon-box-img {
    margin-right: 30px;
}

.w { color: #fff;}

.icon-box-title.w {
    font-size: 40px;
    font-weight: 800;
}

.icon-text.w {
    margin-bottom: 0px;
    font-size: 21px;
}

.box {
    width: 164px;
    height: 164px;
    border-radius: 100% !important;
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle), #213, #112 5%, #112 60%, #213 95%);
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
}
@keyframes bg-spin {
    to {
        --border-angle: 1turn;
   }
}
.box:hover {
    animation-play-state: paused;
}
@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

.personal_link_box {
    
    padding: 10px 20px;
    border-radius: 10px;
}

.p_title.w {
    font-size: 15px;
    margin-bottom: 7px;
}

.p_text.w {
    font-size: 26px;
}

a.copy_btn {
    background-color: #406AFF;
    color: #fff;
    font-size: 17px;
    padding: 6px 15px 10px;
    border-radius: 33px;
    font-weight: 700;
    position: relative;
    z-index: 999;
}

.copy_btn_box {
    align-self: center;
}

.personal_link_box {
    justify-content: space-between;
}

.frgx_box {
    background-image: url(../img/frgx.png);
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    background-position: left;}

.frgx_title.w {
        font-size: 18px;
        margin-bottom: 0px;
        text-align: right;}

.balance {
        font-size: 12px;
        background-color: #000;
        padding: 9px 15px;
        border-radius: 33px;
        margin-left: 15px;
        font-weight: 500;
        color: #fff;}

.balance, .copy_btn {
        background: conic-gradient(from -90deg at 50% 50%,#1df49a 0deg,#f4911d 91.88deg,#c91df4 178.77deg,#6f1adb 270deg,#1df49a 1turn);
    }

.partners { background-color: #191818; 
            padding: 15px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            height: 140px;
            
        }

.partners button{
    color: #fff;
    padding: 8px 30px;
    
    background: #f03759;
    z-index: 1
     !important;
    position: relative;
}  
.partners button:hover{
    background: #01ad68;
    color: #fff;
}          

.partners-title {
                font-size: 17px;
                color: #929293;
            }

.partners-text {
                font-size: 26px;
                margin-bottom: 0px;
                font-weight: 800;
            }

.margin-top { margin-top: 35px;}

.partners1 {
    width: 85px;
    position: absolute;
}

.partners1, .partners2, .partners3 {
    width: 85px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 0;
}


.partners-text, .partners-title {
    position: relative;
    z-index: 4;
}
.partner-img{
    width: 100px;
}

img.partners4 {
    width: 237px;
    position: absolute;
    top: 1px;
    left: 50%;
}

.context {
    width: 100%;
    position: absolute;
    top: 23px;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area-1{
    background: #1b24d0;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
    height:100vh;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.ani-1 {
    overflow: hidden;
    position: relative;
    height: 131px;
    border-radius: 10px;
}

.align-self {
    align-self: center;
}

.green-text {
    font-size: 13px;
    font-weight: 600;
}

.green-box {
    justify-content: space-between;
    position: relative;
    z-index: 2;
    background-color: #00800026;
    padding: 7px 8px 7px 15px;
    border-radius: 33px;
    margin-top: 10px;
}

.green-img {
    width: 22px;
}

.col_2 {
    padding: 5px !important;
}

.g { color: green;}

.d-flex.part20 {
    justify-content: space-between;
}

.sec_2_title {
    font-size: 31px;
    margin-bottom: 0px;
    font-weight: 600;
    text-align: center;
}

.sec_2 {
    margin: 40px 0px;
}

span.info {
    font-size: 13px;
    background-color: blue;
    padding: 10px;
    border-radius: 33px;
}

.x3, .x4, .xq, .xm {
    background-position: bottom right;
    padding: 35px 20px;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
}

.x3 {
    background-image: linear-gradient( to right, rgb(233 233 233 / 7%), rgb(133 133 133 / 4%) ), url(../img/3-1.png);
    box-shadow: 0px 0px 10px -6px #fff;
    width: 100%;
    background-size: cover;
 }

 .x4 {
    background-image: linear-gradient( to right, rgb(233 233 233 / 7%), rgb(133 133 133 / 4%) ), url(../img/4-1.png);
    box-shadow: 0px 0px 10px -6px #fff;
    width: 100%;
    background-size: cover;
 }

 .xq {
    background-image: linear-gradient( to right, rgb(233 233 233 / 7%), rgb(133 133 133 / 4%) ), url(../img/2-1.png);
    box-shadow: 0px 0px 10px -6px #fff;
    width: 100%;
    background-size: cover;
 }

 .xm {
    background-image: linear-gradient( to right, rgb(233 233 233 / 7%), rgb(204 124 39 / 4%) ), url(../img/5-1.png);
    box-shadow: 0px 0px 10px -6px #fff;
    width: 100%;
    background-size: cover;
    
}


.x3_title {
    font-size: 20px;
    font-weight: 700;
}

.blue-box-item {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin: 4px;
}

.blue-box-item1 { background-color: #0000ff;}

.blue-box-item2 { background-color: #7637FD;}

.blue-box-item3 { background-color: #D03A94;}

.blue-box-item4 { background-color: #ffb400}

.preview {
    color: #fff !important;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 12px 11px;
    border-radius: 10px;
}

.preview1 { background-color: #f03759}

.preview2 { background-color: #008fff}

.preview3 { background-color: #00ad68}

.preview4 { background-color: #ffb400}


.align-self_end {
    align-self: end;
}

.margin-top {
    margin-top: 33px ;
}

.arrow i {
    
    font-size: 13px;
    background-color: #fff;
    padding: 4px;
    border-radius: 100%;
    margin-left: 9px;
}

.arrow1 {transform: rotate(-36deg); }

.arrow1 { color: #f03759}

.arrow2 { color: #008fff}

.arrow3 { color: #00ad68}

.arrow4 { color: #FFA243;}


.table-row:hover {
    /* margin-left: -60px;
margin-right: -40px; */
margin-top: -1px;
border: none;

border-radius: 6px;
box-shadow: 0 10px 30px 0 rgba(109,114,152,.25);
background-color: #1d1919;
}

.table-row { padding: 15px 0px;
        animation-delay: 3s;
        border-bottom: 1px solid #9a9999;}

.table-recent, .partners-table-box, .link_sen2, .chart_01 {
    background-color: #191818;
    padding: 20px;
    border-radius: 10px;
}

.user {
    background-color: #4f5255;
    color: #fff;
    padding: 10px;
    border-radius: 100%;
    font-size: 20px;
}

.joined {
    color: #929292;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
}

.user-id {
    color: blue;
    background-color: #272C3C;
    padding: 5px 10px;
    border-radius: 33px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
}

.arrow-up-right {
    font-size: 15px;
    margin-right: 15px;
}

.minutes {
    color: #9a9090;
    font-size: 16px;
    margin-bottom: 0px;
}

.members-title {
    font-size: 14px;
    color: #727272;
    margin-bottom: 20px;
}

.green-text_m {
    font-size: 16px;
    font-weight: 700;
}

.border-w {
    border: 1px solid #ffffff1f;
    margin-top: 15px;
    margin-bottom: 15px;
}

.see-more {
    background-color: #3d3d3d;
    width: 100%;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.x3-text {
    font-size: 13px;
}

.dashboard-logo {
    width: 73px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.accordion-item {
    border: none !important;
}

.accordion-button, .link-title-box{
    color: #fff !important;
    background-color: #242526 !important;
    border-radius: 10px !important;
    font-weight: 600;
}

#headingOne {
    background: #0000 !important;
}

.accordion-item {
    background-color: #0000 !important;
    border-radius: 10px !important;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.partners-title1{
    font-size: 30px !important;
}

.accordion-collapse {
    background-color: #242526 !important;
    border-radius: 10px !important;
    margin-top: 15px !important
 }

 .input-form {
    width: 100%;
    padding:10px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    margin-top: 11px;
    background-color: #000 !important;
    color: #fff;
}

.input-form:focus-visible { border: none !important;
                            outline: none !important;}

.form-lable { font-size: 16px;}

.accordion-collapse .collapse .show { margin-top: 15px !important;}

.sub-btn, .rest-btn {
    font-size: 19px;
    padding: 8px 20px;
    border-radius: 10px;
    margin-top: 15px;
    border: none;
    color: #fff;
    font-weight: 600;
}

.rest-btn { margin-left: 15px ;}

.sub-btn { background-color: #0000ff;}

.rest-btn { background-color: #ffa243;}

.partners-table-box, .link_sen2 { margin-top: 20px;}

.table-row { border-bottom: 1px solid #4a4a4a;}

.partners-table {
    overflow-x: scroll;
}

/* .table-partners02 {
    width: 1501px;
} */


.partners-table-box {
    display: block;
    overflow: hidden;
    position: relative;
}

.partners-table { overflow-x: scroll;}


.table-data {
    color: #fff;
    padding: 12px 0px;
    font-size: 14px;
}

.container-hidden {
    overflow-x: hidden !important;
}

.container.container-hidden {
    max-width: 1032px;
    overflow: hidden;
}

.container {
    overflow: hidden;
}

.table-partners02 {
    width: 100%;
}

.table-heading {
    padding: 10px 0px;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 30px;
  }

::-webkit-scrollbar-track {
    background: #5B5C5C; 
    border-radius: 30px;   
  }

  .link-title-box {
    padding: 10px 20px;
}

.link-title {
    margin-bottom: 0px;
    font-size: 30px;
    font-weight: 600;
}

.right { text-align: right;}

.link_sen02 {
    margin-top: 18px;
}


@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
main {
  margin: 0px;
  padding:0px;
}

#bar-chart {
    width: 100%;
    height: 242px;
    position: relative;
    overflow: hidden;
}
#line-chart {
  width: 500px;
  height: 300px;
  position: relative;
}
#pie-chart {
  width: 500px;
  height: 250px;
  position: relative;
}

.chart_01 { 
    padding: 20px 20px 20px 0px !important;
}

.table-icon {
    padding: 7px 11px;
    background-color: red;
    border-radius: 100%;
}

.g { color: #07bc0c;}

.table-income {
    width: 1000px;
}
.accordion-button::after {
    background-image: url(../img/filter.png) !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../img/filter.png) !important;
    transform: rotate(-180deg) !important;}


@media (max-width: 767px) { 
    .d-flex.icon-box {
        margin-bottom: 20px;
    }

    label.form-lable.w {
        margin-top: 10px;
    }

    .sub-btn, .rest-btn {
        font-size: 16px;}

    a.preview {
        font-size: 15px;
        padding: 8px 10px 11px;
    }

    .blue-box-item {
        width: 25px;
        height: 25px;
    }

    .x3 {
        padding: 15px;
    }

    .sec_2_title {
        font-size: 22px;
    }

    .x3_title {
        font-size: 15px;
    }

    .p_text.w {
        font-size: 19px;
    }

    a.copy_btn {
        font-size: 14px;
    }

    .partners-text {
        font-size: 22px;
    }

    img.logo {
        width: 44px;
        margin-bottom: 0px;
        margin-top: 7px;
    }

    header.nav_header1 {
        display: flex;
        justify-content: space-between;
        padding: 0px 8px 0px 15px;
    }

    .joined {
        font-size: 12px;
        margin-left: 1px;
    }

    .icon-box1 div i{
        font-size: 10px;
        color: #fff;
    }
   
    

    .user-id {
        font-size: 11px;
        margin-left: 8px;
    }

    .table-row:hover {
        padding-left: 5px;
        padding-right: 5px;
    }

    .table-recent {padding: 10px;}

    .minutes {
        font-size: 11px;
    }

    .members_received {margin-top: 15px;}

    .nav_header1 .menu-toggle {
        margin-top: 20px;
    }

    /* .table-recent1 {
        overflow-x: scroll;
    } */

    .d-flex.part20 {
        justify-content: left;
        /* flex-direction:column  !important; */
    }
    .x-image img {
        width: 108px !important;
    }
    .align-self_end {
        align-self: center;
      
    }
    a.preview {
        font-size: 13px !important;
    }
    .xm {
        
        background-position: bottom;
    }
    .right{
        text-align: start;
        margin-top: 10px;
    }
    .link-title{
        margin-bottom: 30px;
    }


    }

    @media (max-width: 576px) { 
/* 
.d-flex.part20.table-row {
    width: 470px;} */

    .partners-text {
        font-size: 17px;
    }
    .p_text.w {
        font-size: 15px;
    }
    .d-flex.part20 {
        /* justify-content: left; */
        flex-direction:row  !important;
    }
} 

@media (min-width: 992px) { 
.dashboard-toolbar.d-flex.part20 {
    display: none !important;
}
}

@media (min-width: 100px) and (max-width: 992px) {
.container {
    max-width: 992px !important;
}

.dashboard-content {
    padding: 2px;
}

}

@media (min-width: 993px) and (max-width: 1199px) { 
    /* .container {
        max-width: 1100px !important;
    } */
}

@media (max-width: 1199px) {
    .blue-box-item {
    width: 27px;
    height: 27px;
    border-radius: 6px;
    margin: 4px;} 

    a.preview {
        font-size: 16px;
    }
}

body { overflow-x: hidden !important;}

.dashboard-app {
    overflow-x: hidden;
}

.nav-title{
    background-color: #242526;
    margin: 0px 20px;
    text-align: center;
    padding: 10px 48px;
    border-radius: 10px;
    font-size: 17px;
    color: #fff !important;
}

.navbar-menu{

}
.navbar-menu ul{
    padding: 0px;
    margin: 0px;
    object-fit: cover;
}

.navbar-menu ul li{
   
    margin: 0px 20px;
    padding: 10px 5px 10px 20px;
    border-radius: 10px;
    font-size: 17px;
    color: #fff !important;
    list-style: none;
}
.navbar-menu ul li:hover{
    background-color: #242526;
}
.logout-btn{
    background: #D03A94;
    padding: 2px 5px 2px 20px !important;
}
.logout-btn:hover{
    background: #D03A94 !important;
}
.list-active{
    background-color: #242526;
}
.list-link span{
    width: 36px;
    font-size: 19px;
    margin-right: 10px;
}
.list-link{
    display: block;
    background: transparent;
}

.list-dropdownmenu{
    background-color: #242526;
    margin: 10px 20px;
    border-radius: 10px;
    padding: 10px 0px ;

    
}
.list-dropdownmenu  li{
    padding: 0px 10px 0px 0px !important;
    margin: 0px 20px !important;
}
.list-dropdownmenu  li a{
    display: flex;
    padding: 7px 20px;
    border-radius: 10px;
}
.list-dropdownmenu  li a:hover{
    background-color: #424344;
}
.list-link-flex{
    display: flex;
    justify-content: space-between;
}


/*mobile-header*/
.main-mobile-header{
    width: 100%;
    display: flex;
    display: none;
}
@media only screen and (max-width: 992px) {
    .main-mobile-header{
        display: block;
    } 
}
.header-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.m-logo img{
    width: 70px;
}
.m-sidebar{
  
}
.mobile-sidebar{
    position: fixed;
    left: -300px;
    top:0;
    background-color:#191818;
    width: 300px;
    height: 100vh;
    color: #fff !important;
    z-index: 999;
    visibility: hidden;
    transition: 0.5s;
}
.new_sidebar{
    visibility: visible;
    left: 0;
    
}
 .mobile-sidebar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 5px 10px;
 }
 .mobile-sidebar-header img{
    width: 70px;
 }
 .mobile-sidebar a{
    color: #fff !important;
 }
 .list-btn-div{
    margin: 10px 20px 10px 30px;
 }
 .matamask-btn{
    background: #ee3483 !important;
    color: #fff !important;
    padding: 7px 30px !important;
    font-size: 17px !important;
 }

 .x-image img{
    width: 200px;
 }

 /* ---------lavel_css---------- */

.lavel_sel_head { background-color: #406AFF;
    border-radius: 10px;
    padding: 20px;}

.lavel_sel_head.d-flex {
justify-content: space-between;
}

.lavel_head_title {
font-size: 22px;
font-weight: 700;
}

.lavel_head_up {
font-size: 16px;
font-weight: 600;
}

.lavel_head_up span{
background-color: black;
padding: 10px;
border-radius: 13px;
margin-left: 16px;
font-size: 13px;
}

.sel_img {
border-radius: 12px;
}

.carousel_sel { margin: 40px auto;
/* box-shadow: 1px 2px 20px #bad2e54d;
background-color: #0000ff; */
border-radius: 10px;}

.carousel-control-prev i, .carousel-control-next i {
color: #fff !important;
font-size: 20px;
z-index: 9999;
position: relative;
}

.carousel-caption {
top: 44px !important;
right: 0px !important;
left: 0px !important;
}

.sel_head_up span {
background-color: black;
padding: 5px 15px;
margin-left: 6px;
border-radius: 10px;
}

.sel_head_up {
font-size: 24px;
font-weight: 600;
}

.main_box { max-width: 700px;
margin:20px auto;}

.di_box {
background-color: #fff;
padding: 20px;
border-radius: 10px;
}

.b { color: #000;}

.carousel_box {
max-width: 100%;
margin: auto;
background-color: #406aff;
padding: 20px;
border-radius: 10px;

}




.carousel-control-prev, .carousel-control-next {
background-color: none !important;
border-radius: 10px;
opacity: 1 !important;
}

.carousel-control-prev-1{
    width: 5% !important;
    left: -0px !important;
   
}
.carousel-control-prev-1 i{
    background-color: #333;
    padding: 10px 10px;
    border-radius: 100px;
}
.carousel-control-next-1 i{
    background-color: #333;
    padding: 10px 10px;
    border-radius: 100px;
}
.carousel-control-next-1{
    width: 5% !important;
    right: -0px !important;
    
}
.carousel-box-header{
    display: flex;
    justify-content: space-between;
}
.carousel-box-header li{
    list-style: none;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #dcdada;
}
.carousel-box-header-lvl{
    font-size: 18px;
   font-weight: 600;
}
.lulvalue{
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    padding: 7px 15px;
}
.lavel-box-main{
    display: flex;
    justify-content: space-around;
}
.show-label-list{
    text-align: center;
    display: flex;
    gap: 10px;
    justify-content: center;
}
.show-label-list input{
        background-color: #333;
        padding: 8px 5px;
        border-radius: 100px;
        font-size: 13px;
        font-weight: 500;
        color: #fff;
} 
.val-input-btn-1{
    padding: 20px 15px !important;
} 
.val-input-btn-11{
    padding: 51px 35px !important;
}   
.val-input-btn-12{
    padding: 30px 25px !important;
}  
.val-input-btn-2{
    padding: 15px 12px !important; 
}
.di_box {
margin: 5px;
}
.c-overflow-scroll{
    overflow-x: scroll;
}
.c-width{
    width: 1600px;
}
.c-width-1{
    width: 2180px;
}

@media (max-width: 1400px) { 
.carousel_box {
max-width: 100% !important;}

.carousel-control-next, .carousel-control-prev {
margin-top: 30px;
display: inline-block !important;
position: relative !important;}

.carousel-control-prev i, .carousel-control-next i {
font-size: 30px;
padding: 10px;
}
}
.f1{
    position: relative;
}

.spinner1{
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top:70px;
    left: 250px;
}
.h2-text-size{
    font-size: 14px !important;
}
.h2-text-size{
    color: #fff;
    font-size: 20px !important;
}
@media (max-width: 767px) { 
    ul.navbar-nav.mr-auto.w-100.justify-content-end.navbar_list {
        padding-bottom: 30px !important;
    }
    .val-input-btn-11 {
        padding: 17px 10px !important;
    }
    .val-input-btn-12 {
        padding: 17px 10px !important;
    }
    .val-input-btn-2 {
        padding: 17px 12px !important;
    }
    .o-hiddin{
        /* overflow:hidden; */
      
    }
    .c-overflow-scroll{
        overflow-x: scroll;
    }
    .c-width{
        width: 500px;
    }
    .c-width-1{
        width: 800px;
    }
    button.carousel-control-next.carousel-control-next-1 {
        left: 38px;
    }
    .x-image{
        text-align: center;
    }
}

.footer-btn{
    background-color: red;
}
.f-btn-div{
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
}

.footer-form{
    padding: 50px 0px;
}

.main-row-footer{
    width: 700px;
    margin: 0px auto;
    background-color: #071425;
    padding: 45px 30px;
    border-radius: 20px;
}
.main-row-footer label{
    color: #fff;
    margin-bottom: 20px;
}
.contact_bg11{
    padding: 10px 80px !important; 
}
.contact11{
    /* padding: 16px 23px 13px !important; */
}

/*pagenation-1*/
.pagenation-1{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
a.page-link {
    background: #071425;
    border-color:#D03A94 ;
    color: #fff !important;
}
a.page-link:hover{
    background-color: #D03A94 !important;
    border-color: #D03A94;
}


.page-item:first-child .page-link{
    background-color: #071425 !important;
    border-color: #D03A94;
    color: #D03A94 !important;
}
.page-item:last-child .page-link{
    background-color: #071425 !important;
    border-color: #D03A94;
    color: #D03A94 !important;
}
li.page-item.active a{
    background-color: #008fff !important;
    border-color: #D03A94;
    color: #fff !important;
}
.c-green{
    color: #07bc0c !important;
    font-weight: 600px;
}